import React from 'react';
import {gql, useQuery} from "@apollo/client";
import ProjectSorter from '../ProjectSorter';
import {Link} from "react-router-dom";


const CURRENT_PROJECTS = gql`
    query getProjects {
        projects (where: {visible: true}) {
            id
            title
            description
            category_relationships
            projectDate
            image {
                id
                url
            }
        }
    }
`;

const WORK_INTRO = gql`
    query HomePage {
      page(where: {slug: "work"}) {
        title
        content {
          html
        }
      }
    }
`;

function WorkContent() {
  const { loading, error, data } = useQuery(WORK_INTRO);

  if(loading) return <p>Loading...</p>;
  if(error) return <p>Error: </p>;

  return(
    <div dangerouslySetInnerHTML={{__html: data.page.content.html}} />
  );
}

export default function WorkCollection() {
    const projectTypeSorterFlag = false; //TODO: Replace with the actual flag
    const { loading, error, data } = useQuery(CURRENT_PROJECTS);

    if(loading) return <p>Loading...</p>;
    if(error) {
        return (<p>We're sorry. Something went wrong! </p>);
    }

    const projects = data.projects.map(( { id, title, description, projectDate, image }, i) =>
        <div className='mt-12 align-top projectEntry w-full md:w-1/2 md:inline-block md:pr-6 lg:w-1/3' key={title}>
            {/* TODO: Put this into a component */}
            <div className='flex items-center border-2 rounded-lg bg-white border-black border-solid overflow-hidden h-96 justify-center'>
              <Link to={`/projects/${id}`}>
                <img className='w-full my-auto' src={image.length === 0 ? `https://via.placeholder.com/300x135.png?text=${title}` : image[0]['url']}
                alt={description}
                />
              </Link>
            </div>
            <h4 className="mt-3">{title}</h4>
            <p>{description}</p>
            <Link className="text-lg" to={`/projects/${id}`}>
              <button className='btn'>View Project</button>
            </Link>
        </div>
      );

    return (
      <div className="block m-6">
        <h1>Work</h1>
        {/* TODO: replace with the real flag */}
        { projectTypeSorterFlag === true &&
          <ProjectSorter />
        }
        <WorkContent />
        <div>{ projects }</div>
      </div>
    )


}