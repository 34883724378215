import React, {Component} from 'react';
import './components/projectsorter.style.css'

class ProjectSorter extends Component {
    render(){
        return (
            <div id='project-sorter'>
                <h4>View by project type:</h4>
                <ul>
                    <li><a href='/work/personal'>Personal</a></li>
                    <li><a href='/work/client'>Clients</a></li>
                    <li><a href='/work/fte'>Team Member</a></li>
                </ul>
            </div>
        )
    }
}

export default ProjectSorter;