import './App.css';
import './css/garbage.css'
import './css/isometric.css'
import './css/punk.css'
import './css/vanillabean.css'
import './css/test.css'

import React, {useContext, useEffect} from 'react';
import {Route, Routes, useLocation} from 'react-router-dom';
import TopNav from './components/topnav.component';
import { StyleContext } from './contexts/style.context';
import { FeatureFlagContext } from './contexts/featureflag.context';
import StyleSwitcher from './components/styleswitcher.component';
import Home from "./components/home.component";
import WorkCollection from "./routes/workcollection";
import Work from "./routes/work";
import Contact from "./routes/contact";
import Narratives from "./routes/narratives";
import Projects from "./routes/projects";
import Resume from "./components/resume.component";
import About from './components/about.component';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

const App = () => {
  const location = useLocation();
  const displayHeaderState = location.pathname === '/' ? true : false;
  const {currentStyle, setCurrentStyle} = useContext(StyleContext);
  const defaultStyle = 'garbage';
  const tempStyle = location.pathname === '/resume' ? 'vanillabean' : null;
  const styleForDisplay = tempStyle || currentStyle || defaultStyle;
  const {splashScreenFeature} = useContext(FeatureFlagContext);

  useEffect(() => {
    document.body.className = '';
    document.body.classList.add(`body-${styleForDisplay}`);

  }, [currentStyle]);


  if (displayHeaderState) {
    return (
      <div className='App'>
        <StyleSwitcher/>
        <TopNav displayHeader={displayHeaderState}/>
        { splashScreenFeature && location.pathname === '/' ?
        <div id='big-splash'>This is a big splash TBD</div>
        :
        <></>
        }
        <div className='top-section-header mx-auto'>
          <h1 className='App-header'>raphael.villas</h1>
        </div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="work" element={<WorkCollection />} />
          <Route path="work/:category" element={<Work />} />
          <Route path="contact" element={<Contact />} />
          <Route path="narratives" element={<Narratives />} />
          <Route path="projects/:projectId" element={<Projects />} />
          <Route path="resume" element={<Resume />} />
        </Routes>
      </div>
      )
  } else {
    return (
      <div className='App'>
        {/* TODO: Refactor */}
        { location.pathname !== '/resume' ?
          <>
          <StyleSwitcher/>
          <TopNav displayHeader={displayHeaderState}/>
          </>
          :
          <></>
        }
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="work" element={<WorkCollection />} />
          <Route path="work/:category" element={<Work />} />
          <Route path="contact" element={<Contact />} />
          <Route path="narratives" element={<Narratives />} />
          <Route path="projects/:projectId" element={<Projects />} />
          <Route path="resume" element={<Resume />} />
        </Routes>
      </div>
      )
    };
}

export default App;
