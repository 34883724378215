import React, {useContext, useEffect} from "react";
import { StyleContext } from "../contexts/style.context";

const StyleSwitcher = () => {
  const { setCurrentStyle, currentStyle } = useContext(StyleContext);

  const handleStyleSwitchClick = (evt) => {
    setCurrentStyle(evt.target.value);
  }


  useEffect(() => {
  }, [currentStyle]);


  return (
    <div id='style-switcher' className='text-left text-xs'>
      <form className='style-switcher'>
        <select name='style-switcher' className='p-1'
        onChange={handleStyleSwitchClick}>
          <option value='garbage'>garbage bag</option>
          <option value='isometric'>isometric</option>
          <option value='punk'>digi-punk</option>
        </select>
      </form>
    </div>
  )
}

export default StyleSwitcher;
