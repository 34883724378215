import React from "react";
import {gql, useQuery} from "@apollo/client";

const ABOUT_CONTENT = gql `
    query AboutPage {
      page(where: {slug: "about"}) {
        title
        content {
          html
        }
      }
    }
`;
const About = () => {
  const { loading, error, data } = useQuery(ABOUT_CONTENT);
  if(loading) return <p>Loading...</p>;
  if(error) return <p>Error: </p>;

  return(
    <div className='m-6'>
      <h1>About</h1>
        <div dangerouslySetInnerHTML={{__html: data.page.content.html}} />
    </div>
  )
}

export default About;
