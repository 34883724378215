import React from 'react';
import HubspotContactForm from '../components/hubspotcontactform.component';

export default function Contact() {
  return(
      <div className='m-6'>
        <h1>Contact</h1>
        <HubspotContactForm />

        <h3>Find me here:</h3>
        <ul id='social-links'>
            <li className='linkedin'><a href='https://www.linkedin.com/in/raphaelvillas/' target='_blank' rel="noopener noreferrer">linkedin</a></li>
            <li className='github'><a href='https://www.github.com/phirefly' target='_blank' rel="noopener noreferrer">github</a></li>
            <li className='twitter'><a href='https://www.twitter.com/phirefly' target='_blank' rel="noopener noreferrer">twitter</a></li>
            <li className='instagram'><a href='https://www.instagram.com/shimmytoes/' target='_blank' rel="noopener noreferrer">instagram</a></li>
            <li className='medium'><a href='https://medium.com/@phirefly' target='_blank' rel="noopener noreferrer">medium</a></li>
        </ul>
      </div>
  );
}