import React from 'react';
import {gql, useQuery} from "@apollo/client";
import {useParams, Link} from "react-router-dom";
import Slider from "react-slick";
import {formatRoleName} from "../utils/project_presenter";

export default function Projects() {
  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true
  };

  const { projectId } = useParams();

    const PROJECTS_QUERY = gql`
    query getProjectDetails {
      project(where: {id: "${projectId}"}) {
        title
        description
        category_relationships
        client {
          id
          title
        }
        projectDate
        image {
          id
          url
        }
        projectRole
      }
    }
    `;

    const { loading, error, data } = useQuery(PROJECTS_QUERY);

    if(loading) return <p>Loading...</p>;
    if(error) return <p>Error: </p>;

    const project = data.project;
    const allProjectImages = project.image;

    return (
        <div id='project-details-info' className='max-w-screen-lg' key={project.title}>

          <div className='w-2/5 inline-block align-top p-8'>
            <h4>{project.title}</h4>
            <p className='description'>{project.description}</p>

            <p><em>Project details</em>
              <br />
              <strong>Organization:</strong> {project.client != null ? project.client.title : ''}
              <br />
              <strong>Role:</strong> {
                project.projectRole.map((role, i) => {
                  return formatRoleName(role);
                }).join(', ')
              }


            </p>
            <p>
              <svg className="inline-block mr-2 w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                   xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                      d="M13 5H1m0 0 4 4M1 5l4-4"></path>
              </svg>
              <Link to={`/work`}>Back to work</Link>
            </p>
          </div>

          <div className='w-3/5 w-auto inline-block my-8'>
            <Slider {...settings}>
            { allProjectImages.map((image, i) => {
                return (
                    <img className='' src={ image.url }
                         alt={project.title}
                         key={i}
                         />
                )
            })}
            </Slider>

          </div>
        </div>
    )
}