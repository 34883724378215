import React, { createContext, useState } from "react";

import posthog from 'posthog-js';
posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY,
  { api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST });


const isSplashScreenFeatureEnabled =
process.env.NODE_ENV === 'development'
  ? true
  : posthog.isFeatureEnabled('splash-screen') ;

const isHomePageProjectPreviewFeatureEnabled =
process.env.NODE_ENV === 'development'
  ? true
  : posthog.isFeatureEnabled('home-page-project-preview') ;

const isHomePageNewsFeedFeatureEnabled =
process.env.NODE_ENV === 'development'
  ? true
  : posthog.isFeatureEnabled('home-page-news-feed') ;

const isTopnavAboutFeatureEnabled =
process.env.NODE_ENV === 'development'
  ? true
  : posthog.isFeatureEnabled('topnav-about') ;

export const FeatureFlagProvider = ({children}) => {
  const [splashScreenFeature] = useState(isSplashScreenFeatureEnabled);
  const [homePageNewsFeedFeature] = useState(isHomePageNewsFeedFeatureEnabled);
  const [homePageProjectPreviewFeature] = useState(isHomePageProjectPreviewFeatureEnabled);
  const [topnavAboutFeature] = useState(isTopnavAboutFeatureEnabled);

  const value = {
    splashScreenFeature,
    homePageNewsFeedFeature,
    homePageProjectPreviewFeature,
    topnavAboutFeature
  };

  return <FeatureFlagContext.Provider value={value}>{children}</FeatureFlagContext.Provider>
}

export const FeatureFlagContext = createContext({});