import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { StyleProvider } from './contexts/style.context';
import posthog from 'posthog-js';
import { PostHogProvider} from 'posthog-js/react'
import { FeatureFlagProvider } from './contexts/featureflag.context';



import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider
} from "@apollo/client"

const client = new ApolloClient({
    uri: 'https://api-us-east-1.graphcms.com/v2/ckrhcaabs0oqx01we5ew88p6m/master',
    cache: new InMemoryCache()
});

posthog.init(
    process.env.REACT_APP_PUBLIC_POSTHOG_KEY,
{
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
}
);

posthog.onFeatureFlags(function() {
if (posthog.isFeatureEnabled('splash-screen') || process.env.NODE_ENV === 'development') {
} else {
    console.log("///// Do not show the splash screen...");
}
});


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

render(
    <ApolloProvider client={client}>
    <BrowserRouter>
        <PostHogProvider client={posthog}>
            <FeatureFlagProvider>
                <StyleProvider>
                    <App />
                </StyleProvider>
            </FeatureFlagProvider>
        </PostHogProvider>

    </BrowserRouter>
    </ApolloProvider>,
    document.getElementById('root'),
);