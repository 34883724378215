import React, {useContext, useEffect, useState} from "react";
import { NavLink } from "react-router-dom";
import { StyleContext } from "../contexts/style.context";
import { FeatureFlagContext } from '../contexts/featureflag.context';

const TopNav = (props) => {
  const { currentStyle } = useContext(StyleContext);
  const [isScrolled, setIsScrolled] = useState(false);
  const {topnavAboutFeature} = useContext(FeatureFlagContext);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const content = document.querySelector('.App');
    const windowHeight = window.innerHeight;
    const buffer = 25;
    const isAtBottom = scrollPosition + windowHeight > (content.clientHeight - buffer);

    if (isAtBottom) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  }

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  });


  useEffect(() => {}, [currentStyle]);
  return (
    <div className={`reveal ${isScrolled || props.displayHeader === false ? 'reveal-active' : '' }`}>
      <div>
        <div id='topnav' className='h-4'>
          <ul>
            <li><NavLink className={({isActive}) => (isActive ? 'lnk-home active' : 'lnk-home')} to="/">home.</NavLink></li>
            { topnavAboutFeature &&
              <li><NavLink className={({isActive}) => (isActive ? 'lnk-about active' : 'lnk-about')} to="/about">about.</NavLink></li>
            }
            <li><NavLink className={({isActive}) => (isActive ? 'lnk-work active' : 'lnk-work')} to="/work">work.</NavLink></li>
            <li><NavLink className={({isActive}) => (isActive ? 'lnk-contact active' : 'lnk-contact')} to="/contact">contact.</NavLink></li>
          </ul>
        </div>

      </div>
    </div>
  )
}

export default TopNav;