import React, { createContext, useState } from "react";

export const StyleContext = createContext({
  currentStyle: null,
  setCurrentStyle: () => {
    return null;
  }
});

export const StyleProvider = ({children}) => {
  const [currentStyle, setCurrentStyle] = useState(null);
  const value = { currentStyle, setCurrentStyle };

  return <StyleContext.Provider value={value}>{children}</StyleContext.Provider>
}