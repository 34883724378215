import React from 'react';
import {gql, useQuery} from "@apollo/client";
import { useParams } from 'react-router-dom';
import Slider from "react-slick";

const PROJECTS_BY_CATEGORY = gql`
    query getProjectsById($cat: [Categories!]) {
        projects (where: {category_relationships: $cat, visible: true}) {
            client {
                id
            }
            id
            title
            description
            category_relationships

            image {
                id
                url
            }

        }
    }
`;

export default function Work() {
    const { category } = useParams();
    const mapping = {
        personal: "Personal",
        client: "Client",
        fte: "FTE"
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };


    const mappedCategory = mapping[category.toLowerCase()]
    console.log("*** mappedCategory: ", mappedCategory);

    const { loading, error, data } = useQuery(PROJECTS_BY_CATEGORY, {
        variables: { cat: [mappedCategory] }
    });

    if(loading) return <p>Loading...</p>;
    if(error) {
        console.log("*** Error: ", error);
        return (<p>We're sorry. Something went wrong! </p>);
    }

    const projectsList =  data.projects.map(( { id, title, description, projectDate, image }, i) =>
        <div className='align-top mt-12 projectEntry w-full md:w-1/2 md:inline-block md:pr-6 lg:w-1/3' key={title}>
            <h4>{title}</h4>
            <Slider {...settings}>
                <div>
                    <h3>
                        <a href={`/projects/${id}`}><img src={image.length === 0 ? `https://via.placeholder.com/300x135.png?text=${title}` : image[0]['url']} alt={description} /></a>
                        <p>{description}<br />
                            <a href={`/projects/${id}`}>Project details</a></p>
                    </h3>
                </div>
            </Slider>
        </div>
    );

    return (
        <div className='m-6 inline-block'>
            {projectsList}
        </div>
    )


}