const ProjectPresenter = () => {
}

const formatRoleName = (role) => {
  const str = role.replace('_', ' ');
  return str.charAt(0).toUpperCase() + str.slice(1);
}


export {formatRoleName};
export default ProjectPresenter;