import React from "react";
import {gql, useQuery} from "@apollo/client";
import {Link} from "react-router-dom";

const FeaturedProject = () => {

  const PROJECTS_QUERY = gql`
  query FeaturedProjects {
    featuredProjects(where: {projectType: "default"}) {
      project {
        id
        title
        image {
          url
        }
      }
    }
  }
  `;

  const { loading, error, data } = useQuery(PROJECTS_QUERY);

  if(loading) return <p>Loading...</p>;
  if(error) return <p>Error: </p>;

  const projects = data.featuredProjects;

  return (
    <div>
      <h3>Featured Work</h3>
      <p>
        { projects.map((projectItem => {
          return (
            <Link key={projectItem.id} to={`/projects/${projectItem.project.id}`}><img className='project-preview'
                 src={ projectItem.project.image[0]['url'] }
                 alt={projectItem.project.title }
            /></Link>
            );
        }))}
      </p>
      <Link to='/work'><button className="btn">View More Work</button></Link>
    </div>
  )
}

export default FeaturedProject;