import React from 'react';
import {
    useQuery,
    gql
} from "@apollo/client"

const NARRATIVES_CONTENT = gql `
    query HomePage {
      page(where: {slug: "narratives"}) {
        title
        content {
          html
        }
      }
    }
`;

export default function Narratives() {
    const { loading, error, data } = useQuery(NARRATIVES_CONTENT);

    if(loading) return <p>Loading...</p>;
    if(error) return <p>Error: </p>;

    return(
        <div>
          <div dangerouslySetInnerHTML={{__html: data.page.content.html}} />
        </div>
    );
}
