import React from "react";

const Resume = () => {
  return(
    <div className='m-6'>
      <h1>Raphael G. Villas</h1>
      <p>2000 Mallory Lane, Suite 130-525 | Franklin, TN 37067</p>
      <p>773.269.9601 |
        <a href='https://www.github.com/phirefly' target='_blank' rel="noopener noreferrer">github</a> |
        <a href='https://www.twitter.com/phirefly' target='_blank' rel="noopener noreferrer">twitter</a> |
        <a href='https://www.linkedin.com/in/raphaelvillas/' target='_blank' rel="noopener noreferrer">linkedin</a> |
        <a href='/work'>portfolio</a> |
        <a href='/contact'>contact</a>
        </p>

      <h2>As a <strong>digital craftsman,</strong> I bring a unique blend of creative front-end and technical back-end skills to every project, passionately delivering experiences that are aesthetically pleasing and technically sound. As a <strong>technology leader,</strong> I am dedicated to guiding my team toward excellence through a data-driven, user-centered design approach. Let's create <strong>something extraordinary together!</strong></h2>

      <h3>Experience</h3>
      <h5>DIRECTOR OF PRODUCT ENGINEERING, Click Funnels</h5>
      <em>Eagle, ID (January 2024 – Present)</em>
      <ul>
        <li>TBD</li>
      </ul>
      <h5>DIRECTOR OF PRODUCT ENGINEERING, Ramsey Solutions</h5>
      <em>Franklin, TN (April 2018 – January 2024)</em>
      <ul>
        <li>Successfully led teams of product, marketing, and QA engineers to deliver compelling digital products for Financial Peace Partnerships and Ramsey+ Coaching business units. Guided technical direction and ensured timely delivery of product iterations.</li>
        <li>Managed the migration from an outdated e-commerce system to a KonaKart-based store for Financial Peace customers, resulting in a smoother and more efficient user experience. Successfully integrated a digital licensing system.</li>
        <li>Directed the initial launch of Ramsey+ B2B, providing tens of thousands of church members access to the Ramsey+ digital product. This effort was critical to the success of the product.</li>
        <li>Led the effort to overhaul an integration with Salesforce that grew in complexity over the years, moving to a Backend-for-frontend architecure (BFF). </li>
        <li>Automated acquisition processes, driving third party integrations with existing system to optimize webinar offerings.</li>
        <li>Established and upheld team norms which, combined with the improved BFF architecture, resulted in an average cycle time improvement from 4.5 to 1.5 days.</li>
        <li>Collaborated with Security team to implement a secure network architecture through application monitoring and penetration testing, remediating critical security vulnerabilities to improve the overall security posture of the organization.</li>
        <li>Worked with Sales and Customer Success teams to quickly address customer confusion issues, in many cases decreasing ticket counts categorically by more than 50%.</li>
        {/* Resulted in an immediate XX% increase in squad throughput  */}
      </ul>

      <h5 className="pt-5">INSTRUCTOR: ROBOTICS, PRINTING, DIGITAL MEDIA | 4-H Youth Development</h5>
      <em>Franklin, TN (August 2020 – Present)</em>
      <ul>
        <li>Designed and planned six-week curriculums for beginner through advanced students using the LEGO EV3 and Spike Prime platforms, focused on bolstering STEM skills.</li>
        <li>Encouraged student creativity and critical thinking through hands-on robotics projects covering fundamental robotics concepts and computer programming skills.</li>
        <li>Cultivated strong relationships with students, fostering a positive and inclusive learning environment, and inspiring a lifelong passion for technology and engineering.</li>
        {/* <li>Coached students through coding and build issues </li> */}
      </ul>

      <h5 className="pt-5">TRANSFORMATION LEAD | Department of Labor, Wage and Hour Division</h5>
      <em>Chicago, IL (December 2016 – April 2018)</em>
      <ul>
      <li>Successfully coordinated the modernization of legacy systems by synthesizing a unified plan of action across various program areas, improving organizational efficiency and effectiveness.</li>
      <li>Spearheaded operating plan initiatives for Wage and Hour IT modernization and business process enhancement, leading to heightened productivity and improved processes.</li>
      <li>Facilitated workshops across channel areas, including strategic planning, retrospectives, and product discovery.</li>
      <li>Enacted agile methodologies to streamline project delivery and ensure efficient use of resources, resulting in high-quality deliverables and improved project outcomes.</li>
      </ul>

      <h5 className="pt-5">CO-FOUNDER + PRODUCT LEAD | 18F, General Services Administration</h5>
      <em>Chicago, IL (December 2013 – December 2016)</em>
      <ul>
        <li>Orchestrated product delivery efforts of a cross-functional team, including designers, engineers, and open-source contributors.</li>
        <li>Oversaw the establishment of the Trusted Tester Program, a comprehensive product testing initiative, acquiring over 50 beta testers to assess user-centered designs of C2 prototypes, improving product quality through customer feedback and validation.</li>
        <li>Facilitated the creation and adoption of the C2/Communicart Approval Tool, streamlining purchase card procurement for Federal employees, resulting in 500+ active users and over $2.5M in requests completed.</li>
      </ul>

      <h5 className="pt-5">PRESIDENTIAL INNOVATION FELLOW | The White House, GSA</h5>
      <em>Washington, D.C. (June 2013 – December 2013)</em>
      <ul>
        <li> Collaborated in a multidisciplinary team of developers and designers to extend the MyUSA single sign-on, the minimal viable product of what has become <a href='https://www.login.gov' target='_blank'>Login.gov</a>.</li>
        <li> Rapidly prototyped MyUSA platform web applications, including MyGov Tasks, a list generator that facilitates the creation and publishing of MyUSA-enabled tasks.</li>
        <li> Delivered digital services for GSA, performing software engineering and visual design tasks, engaging from original concept to feature completion.</li>
      </ul>

      <h5 className="pt-5">SOFTWARE ENGINEER | Groupon, Inc.</h5>
      <em>Chicago, IL (March 2011 – June 2013)</em>
        <ul>
        <li>Crafted a range of web application features to support Groupon's marketing and sales efforts, including a gift card processing engine and automated Groupon Bucks fulfillment system.</li>
        <li>Led training sessions for non-technical team members in Berlin, ensuring a smooth transition to a new invoice processing system.</li>
        <li>Contributed to the development and launch of the Groupon Now! consumer-facing software and customized marketing tools, aimed at attracting and retaining first-time Groupon buyers.</li>
        <li>Designed and implemented a front-end application based on Backbone.js and APIs, providing increased transparency for Groupon's merchant payment system.</li>
        <li>Ensured adequate code coverage throughout the application test suite to enhance software maintainability.</li>
        </ul>

      <h5 className="pt-5">OWNER / DESIGNER | 2 Big Division, Inc.</h5>
      <em>Chicago, IL (July 2003 – March 2011)</em>

      <ul>
      <li>Provided comprehensive design and production services, including branding, print, and online media.</li>
      <li>Led end-to-end project management, collaborating closely with clients to ensure seamless delivery from discovery to concept creation, information architecture, visual design, engineering, and testing.</li>
      <li>Cultivated and nurtured robust partnerships with a diverse portfolio of esteemed organizations, including Hill’s Pet Nutrition, VML, Firebelly Design, The Cara Program, Epic Academy, Faith in Place, and Clean Slate.</li>
      </ul>

      <h5 className="pt-5">WEB / INTERACTIVE DESIGNER | Big Idea Productions</h5>
      <em>Lombard, IL (June 1999 – April 2003)</em>

      <ul>
      <li>Conceptualized and executed visually impactful digital media for Big Idea’s portfolio of properties, including VeggieTales, 3-2-1 Penguins!, and LarryBoy.</li>
      <li>Led a remote cross-functional team in designing and developing 'BigIdeaFun,' a children's gaming website, ensuring consistent design and technical standards throughout the project.</li>
      <li>Applied user-centered design principles to produce engaging and intuitive interfaces that effectively promote Big Idea's properties.</li>
      </ul>

      <h5 className="pt-5">STAFF CONSULTANT | Metamor Technologies</h5>
      <em>Chicago, IL (April 1997 – May 1999)</em>
      <ul>
      <li>Created interactive coding prototypes using HTML and Javascript, ensuring the seamless deployment of creative assets and troubleshooting scripting and technical issues as needed.</li>
      <li>Led cross-functional teams to deliver user-friendly web interfaces that met client expectations and project timelines.</li>
      <li>Collaborated with clients to understand project requirements, provide design recommendations and ensure project success from concept to launch.</li>
      </ul>

      <h3>Skills</h3>
      <h5>Engineering</h5>
        <p>Languages: Ruby | Javascript | Go | HTML/CSS</p>
        <p>Technologies: AWS | Ruby on Rails | React | Tailwind</p>
        <p>Other: Relational / NoSQL Databases | Test-Driven Development</p>
      <h5>Product</h5>
        <p>Strategic Thinking + Planning | Agile Methodologies | Team Leadership | Product Thinking</p>
      <h5>Design</h5>
        <p>User Research | Visual Design | Interaction Design | Lean UX</p>
      <h5>Tools</h5>
        <p>Github | Jira / Pivotal Tracker / Trello</p>

      <h3>Education</h3>
      <ul>
        <li>Post-Baccalaureate Studies, Graphic Design | University of Illinois at Chicago</li>
        <li>Interactive Media Studies | International Academy of Art and Design</li>
        <li>Bachelor of Science in Nursing | Loyola University of Chicago</li>
      </ul>

      <h3>Affiliations</h3>
      <ul>
      <li>Toastmasters International | Harpeth View Toastmasters </li>
      <li>Rolling Hills Community Church | Family Ministry Leader</li>
      </ul>

      <h3>Recognition</h3>
      <ul>
        <li>Ramsey Solutions | Ramsey+ Emergence Award</li>
        <li>Accelerating Government Nominee | ACT-AIC Igniting Innovation Award</li>
        <li>General Services Administration | Top Team C.R.E.W. Award</li>
      </ul>
    </div>
  )
}

export default Resume;
