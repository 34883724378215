import React, {useContext} from 'react';
import {gql, useQuery} from "@apollo/client";
import { FeatureFlagContext } from '../contexts/featureflag.context';
import FeaturedProject from "./featured_project.component";
import {Link} from "react-router-dom";

const HOMEPAGE_INTRO = gql `
    query HomePage {
      page(where: {slug: "homepage"}) {
        title
        content {
          html
        }
      }
    }
`;

const Home = () => {
  const { loading, error, data } = useQuery(HOMEPAGE_INTRO);
  const {homePageNewsFeedFeature, homePageProjectPreviewFeature, topnavAboutFeature} = useContext(FeatureFlagContext);

  if(loading) return <p>Loading...</p>;
  if(error) return <p>Error: </p>;

  return (
    <div id='homepage-callouts' className='m-6'>
      <div dangerouslySetInnerHTML={{__html: data.page.content.html}} />
      <div>
        <a href='/resume'><button className="btn">View CV</button></a>
      </div>
      { topnavAboutFeature &&
        <div>
          <Link to='/about'><button className="btn">Read More</button></Link>
        </div>
      }
      { homePageProjectPreviewFeature &&
        <FeaturedProject />
      }

      { homePageNewsFeedFeature &&
        <div>
          <h3>Recent posts</h3>
          <p><em>Twitter</em></p>
          <p><em>Instagram</em></p>
          <p><em>Medium</em></p>
        </div>
      }
    </div>
  );
}

export default Home;